<template>
  <div class="wrapper">

    <div class="body" style="padding:0;">

      <Slider></Slider>

      <section style="position: relative; z-index: 500; margin-top: 10px;" class="feature-menu p0 pb-1">
        <div class="icons-wrapper top-menu-items d-flex justify-content-between">
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/my-account">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/bonus.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Bonus</span>
            </router-link>
          </div>
      
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/gifts">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/promos.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Promotions</span>
            </router-link>
          </div>
      
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/my-account">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/deposit.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Deposit</span>
            </router-link>
          </div>
      
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/mobile-app">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/download.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Download</span>
            </router-link>
          </div>
        </div>
      </section>
      




      <div style="height: 35px;margin-top: 5px;margin-bottom: 7px;" class="marquee-container">
        <marquee behavior="scroll" direction="left" scrollamount="12" @mouseover="stopScroll" @mouseout="startScroll">
          <span v-for="(notification, index) in notifications" :key="index" style="color: var(--yellow); margin-right: 200px;">
            <img style="width: 20px;" src="/img/topNav/bell.png" />
            {{ notification.phone }} won {{ notification.amount }}. {{ notification.game }}
          </span>
        </marquee>
      </div>

      <div style="" class="cas-header-wrapper">
        <div style="background-color: var(--darkBackground); color: white; overflow-x: auto; white-space: nowrap;" class="catsec" id="catsec">
          <span v-for="(item, index) in categories" class="cattit" @click="getCasinoGames(item)" :class="isActive(item)"
            v-bind:key="getKey(index)"
            style="display: inline-block; padding: 6px;border-radius: 26px;margin-right: 6px;min-width: 80px;text-align:center;">
            {{ item.name }}
          </span>
        </div>
      </div>
      <ShimmerCasino v-if="loading"></ShimmerCasino>


      <div class="row m-0 p-0" style="background-color: var(--darkBackground)">

        <div v-show="searchgamesdata.length > 0" class="casino-headers " style="">
          <span style="color: var(--blackText);">Search Results</span>
        </div>

        <!-- new card design -->
        <div v-show="searchgamesdata.length > 0" class="row m-0 p-0 pb-5"
          style="background-color: var(--darkBackground)">
          <div style="text-align: center;" v-for="(o, index) in searchgamesdata" v-bind:key="getKey(index)"
            class="casino-game col-lg-3 col-md-3 col-sm-3 col-6 m-0 p-1">
            <div class="game-icon">
              <img loading="lazy" :src="getImg(o.image)" alt="Avatar" data-toggle="modal"
                :data-target="'#casinoGames' + o.game_id"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;" />
            </div>

            <div class="modal fade" :id="'casinoGames' + o.game_id" tabindex="-1" role="dialog"
              :aria-labelledby="'casinoGamesLabel' + o.game_id" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" :id="'casinoGamesLabel' + o.game_id">{{ o.game_name }}</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" :src="o.image" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2"
                        :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: o.provider_id, gameName: o.game_name, gameID: o.game_id, launchURL: o.launch_url, launchType: o.launch_type, launchParameters: o.launch_parameters }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="scroller">


        <div v-show="searchgamesdata.length == 0" class="row m-0 p-0 pb-5" style="background-color: var(--gray-white)">

          <div v-for="(i, x) in allgamesdata" :key="getKey(x)"
            class="casino-game col-lg-6 col-md-6 col-sm-6 col-6 m-0 p-1" style="text-align: center;">

            <div class="game-icon">
              <img loading="lazy" :src="getImg(i.image)" alt="Avatar" @click="showOverlayAndButtons(i)"
                style="max-width: 100%; object-fit: contain; border-radius: 16px; padding: 0; background-color: var(--lightest-gray); border: solid var(--light-gray) 1px;" />

              <div class="random-number">
                <span>
                  <img style="width: 16px;" src="/img/topNav/user-white.png" />
                </span>
                {{ getRandomNumber() }}
              </div>

              <!-- Button Container -->
              <div class="button-container" style="display: flex; justify-content: space-between; margin-top: 3px;">
                <router-link class="play1-button"
                  :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id, launchURL: i.launch_url, launchType: i.launch_type, launchParameters: i.launch_parameters }, }">
                  play now
                </router-link>
                <router-link class="demo-button"
                  :to="{ name: 'casinogames', params: { IsDemo: 1, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id, launchURL: i.launch_url, launchType: i.launch_type, launchParameters: i.launch_parameters }, }">
                  demo
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import Vue from 'vue'
import ShimmerCasino from "./shimmer/ShimmerCasino.vue";
import ScrollLoader from 'vue-scroll-loader'
import casino from "@/services/casino";
const BottomNavigation = () => import('./BottomNavigation.vue')


const Slider = () => import('./SliderCasino.vue');

Vue.use(ScrollLoader)
export default {
  name: "Home",
  components: {
    Slider,
    ShimmerCasino,
    BottomNavigation
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          "id": 9,
          "title": "Popular"
        },
        {
          "id": 4,
          "title": "Slots"
        },
        // {
        //   "id" : 16,
        //   "title" : "Video Slots"
        // },
        // {
        //   "id" : 12,
        //   "title" : "Jackpot Games"
        // },
        {
          "id": 17,
          "title": "Classic Casino"
        },
        {
          "id": 8,
          "title": "Table Games"
        },
        // {
        //   "id" : 7,
        //   "title" : "Poker"
        // },
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        "id": 3,
        "name": "Crash"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 100,
      isSearchVisible: false,
      showOverlay: false,
      showButtons: false,
      currentGame: null,
      buttonTimeout: null,
      notifications: [
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Easter Plinko' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Statch Alpaca' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Comet crash' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Capymania Green' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Mine sweeper' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Limbo' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Heads Tails' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' Dragons Crash' },
        { phone: this.randomizePhone(), amount: this.randomizeCash(), game: ' French Roulette' },
      ]
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino");

    this.getCasinoGames(this.casinoCategoryActive);

    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();

    this.showSlides();
    var touchableElement = document.getElementById('scroller');
    touchableElement.addEventListener('touchstart', function (event) {
      vm.touchstartX = event.changedTouches[0].screenX;
      vm.touchstartY = event.changedTouches[0].screenY;
    }, false);

    touchableElement.addEventListener('touchend', function (event) {
      vm.touchendX = event.changedTouches[0].screenX;
      vm.touchendY = event.changedTouches[0].screenY;
      vm.handleGesture();
    }, false);


  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    randomizePhone() {
      const randomDigits = Math.floor(Math.random() * 90 + 10);
      return `254****${randomDigits}`;
    },
    randomizeCash() {
      const randomDigits = Math.floor(Math.random() * 90 + 200);
      return `Ksh. ${randomDigits}`;
    },
    stopScroll(event) {
      event.target.stop();
    },
    startScroll(event) {
      event.target.start();
    },
    getRandomNumber() {
      return Math.floor(Math.random() * (477 - 50 + 1)) + 50;
    },
    showOverlayAndButtons() {

      this.buttonTimeout = setTimeout(() => {
        this.showButtons = true;
        this.showOverlay = true;
      }, 1000);
    },

    toggleSearch() {
      this.isSearchVisible = !this.isSearchVisible;
    },
    handleGesture: function () {
      var scrollLeft = document.getElementById('catsec').scrollLeft
      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          this.getCasinoGames(this.casinoCategoryActive);

          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          this.getCasinoGames(this.casinoCategoryActive);
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add('d-none');
    },


    performSearch: function () {

      var keyword = this.to_search;
      var games = this.allgames;

      console.log(keyword);

      var results = games.filter(function (el) {

        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null ? false : game_name.match(keyword.toLowerCase()).length > 0;

      });

      console.log('search results ' + JSON.stringify(this.searchgamesdata, undefined, 2))

      if (results.length > 0 && keyword !== "") {

        this.searchgamesdata = results;

      } else {

        this.searchgamesdata = [];

      }

    },

    keyUpSearch: function () {

      if (this.to_search == '') {

        this.searchgamesdata = [];

      }

    },

    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function (activeCategory) {

      this.loading = true;
      this.casinoCategoryActive = activeCategory;

      var vm = this;

      //var platform = this.isMobile() ? 'mobile' : 'desktop';
      //var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      var path = "/games?provider_id=13" + "&category_id=" + activeCategory.id + "&page=" + this.currentPage + "&per_page=" + this.perPage;

      casino.get(path)
        .then((resp) => {

          vm.allgamesdata = resp.data.data;
          vm.allgames = vm.allgamesdata;

          console.log("allgames --> " + JSON.stringify(vm.allgames, undefined, 2));

          this.loading = false;

          window.scrollTo(0, 0);

        });

    },

    getCategories: function () {

      var vm = this;

      var path = "/category?provider_id=13";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })
    },

  },
  beforeDestroy() {
    if (this.buttonTimeout) {
      clearTimeout(this.buttonTimeout);
    }
  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>

<style scoped>
.catsec::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.catsec {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.marquee-container {
  height: 27px;
  margin-top: 0px;
  padding: 2px;
  background: #000;
  width: 100%;
  border-radius: 1px;
  padding: 3px;
}

.marquee-text {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
  /* Adjusted timing for smoothness */
}

.marquee-text span {
  margin-right: 50px;
  /* Adjust space between items */
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.random-number {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #000000b8;
  color: #000;
  padding: 0px 3px;
  border-radius: 5px;
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  color: white !important;
  font-weight: 700;
}

@media (max-width: 768px) {
  .random-number {
    top: 5px;
    right: 5px;
    font-size: 10px;
  }
}

.game-icon {
  position: relative;
  width: 100%;
  height: auto;
}

/* Dark overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  /* Prevents clicks when invisible */
}

.show-overlay {
  opacity: 1;
  pointer-events: auto;
  /* Enable clicks when visible */
}




.game-icon:hover .overlay {
  opacity: 1;
  pointer-events: auto;
  /* Enable clicks when visible */
}

/* Buttons inside the overlay */

.overlay-btn {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  /* Ensure buttons are on top of the overlay */
}

.casino-game:hover .overlay {
  opacity: 1;
  /* Show overlay on hover */
}

/* Button hover effect */

/* Button hover effect */

.wallet-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
}

.form-wrapper1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-wrapper1 input {
  background: var(--primary-bg-light);
  color: white;
}

.alert {
  position: relative;
  border-radius: 0.5rem;

}

h4 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.wallet-btns-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.wallet-btns-grid .odd-btn {
  padding: 0.2rem;
  font-size: 0.8rem;
  background: var(--primary-bg-light);
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: auto;
}

.tabs-list {
  width: 100%;
}

.tabs-list ul {
  display: flex;
  justify-content: center;
  list-style: none;
  justify-content: flex-start;
  padding: 0;
  gap: 1rem;
  border-bottom: 1px var(--primary-bg-light) solid;
}

.tabs-list ul li {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding: 10px 0;
  margin-bottom: -1px;
}

.tabs-list ul li.active {
  border-color: var(--yellow);
  color: var(--yellow);
  font-weight: bold;
}

.balance {
  background: #000C15;
  color: var(--yellow);
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  display: table;
  font-size: 0.8rem;
}

.icon-container {
  background-color: transparent;
  width: 70px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.category-tabs {
  max-width: calc(100% - 80px);
  /* Adjust the maximum width to leave space for the button */
  overflow-x: auto;
  /* Enable horizontal scrolling */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-num {
  color: white;
  font-weight: 600;
  padding: 3px;
}

.nav-link {
  color: white;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.nav-link {
  background-color: #101B21;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  margin-left: 2px;
}

.nav-tabs .nav-link.active {
  color: green !important;
  font-weight: 600;
  background-color: #e1f807;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.hover {
  color: green !important;
  font-weight: 600;
  background-color: grey;
  border-color: #dee2e6 #dee2e6 #fff;
}

.carousel-container {
  overflow-x: auto;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-arrow-left {
  left: 0;
}

.carousel-arrow-right {
  right: 0;
}

a:active {
  color: green;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for leaderboard section */
.leaderboard-container {
  margin-top: 20px;
}

/* Style for leaderboard list */
.leaderboard-list {
  list-style-type: none;
  padding: 0;
}

/* Style for leaderboard list item */
.leaderboard-item {
  margin-bottom: 5px;
}

/* Style for MSISDN, name, and bets */
.msisdn {
  font-weight: bold;
}

.name {
  margin-left: 5px;
}

.bets {
  margin-left: 5px;
}

/* Responsive styling */
@media only screen and (max-width: 768px) {

  /* Adjust font size for smaller screens */
  .msisdn,
  .name,
  .bets {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {

  /* Adjust margins for smaller screens */
  .name,
  .bets {
    margin-left: 0;
    margin-top: 5px;
    /* Add space between items */
  }
}


@media (min-width: 768px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 4 games per row with 20px margin between them */
  }
}

@media (max-width: 767.98px) {
  .game-card {
    flex: 0 0 calc(33.33% - 5px);
    /* 3 games per row with 20px margin between them */
  }
}

.game-card {
  position: relative;
}

.game-icon img {
  width: 100%;
  height: auto;
  /* Ensure image maintains aspect ratio */
  border-top-left-radius: 5px;
  /* Adjust border radius to match card */
  border-top-right-radius: 5px;
  /* Adjust border radius to match card */
}

.game-details {
  width: 100%;
}

.game-details p {
  margin-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 20px;
  /* Adjust font size as needed */
  color: red;
  /* Adjust color as needed */
  cursor: pointer;
}

.play1-button {
  color: white;
  width: 100%;
  padding: 3px 3px;
  font-weight: 700;
  background: linear-gradient(90deg, #ffb400f2, #bc8500f2);
  border-radius: 16px;
}

.demo-button {
  width: 60%;
  border-radius: .4rem;
  background: #34354d;
  color: #fff;
  text-align: center;
  border-radius: 16px;
}


.game-card:hover {
  transform: translateY(-5px);
}

.filter-dropdown select {
  appearance: none;
  /* Remove default appearance */
  background-color: #fff;
  /* Background color */
  border: 1px solid #ccc;
  /* Border */
  border-radius: 5px;
  /* Border radius */
  padding: 8px 12px;
  /* Padding */
  font-size: 11px;
  /* Font size */
  font-weight: 600;
  /* Font weight */
  cursor: pointer;
  /* Cursor on hover */
}

/* Dropdown arrow */
.filter-dropdown select::after {
  content: "\25BC";
  /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevents the arrow from being clickable */
}

/* Hover effect */
.filter-dropdown select:hover {
  border-color: #aaa;
  /* Border color on hover */
}

/* Focus effect */
.filter-dropdown select:focus {
  outline: none;
  /* Remove outline */
  border-color: #555;
  /* Border color on focus */
}

.filter-dropdown select option {
  background-color: #fff;
  /* Background color */
  color: #333;
  /* Text color */
  font-size: 11px;
  /* Font size */
}

.filter-dropdown select:disabled {
  opacity: 0.5;
  /* Reduced opacity */
  cursor: not-allowed;
  /* Disabled cursor */
}

.search-bar {
  position: relative;
}

.search-bar input[type="text"] {
  width: calc(100% - 30px);
  /* Subtracting the total padding (15px on each side) */
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  outline: none;
}

.search-bar input[type="text"]:focus {
  border-color: #007bff;
}

.search-bar .search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #ccc;
  cursor: pointer;
}

.search-bar .search-icon:hover {
  color: #007bff;
}

.custom-input {
  width: 100%;
  /* Adjusted width to match the search icon */
  padding: 6px 12px;
  /* Adjusted padding to match the filter button */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  outline: none;
}

.custom-input:focus {
  border-color: #007bff;
}

.custom-input::placeholder {
  color: #666;
  /* Placeholder text color */
  font-style: italic;
  /* Italicize placeholder text */
}

.BottomFooter {
  margin-top: 20px;
  /* Adjust this value as needed */
}

.vue-carousel-slide {
  padding: 10px;
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .vue-carousel-slide {
    padding: 0 10px;
    /* Adjust padding for smaller screens */
  }
}

/* Button styling */
.nav-link {
  display: block;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: white !important;
  cursor: pointer;
}

.nav-link.active {
  background-color: #e1f807;
  font-weight: 600;
  color: white !important;
}

.nav-link:hover {
  background-color: #fff;
  font-weight: 600;
  color: green !important;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
}

.tab-navigation button {
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.tab-navigation button.active {
  background-color: green;
  color: white;
}

.hide-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

/* Adjustments for responsiveness */
@media (max-width: 767.98px) {
  .game-details {
    text-align: center;
    /* Center align the game details */
  }
}
</style>